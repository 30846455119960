import React from "react";
import { FaChevronRight } from "react-icons/fa";
import GoldBgBtn from "./buttons/GoldBgBtn";
import GoldBorderBtn from "./buttons/GoldBorderBtn";

const PXAPortal = () => {
  return (
    <div className="md:ml-[17rem] md:w-full flex flex-col gap-6 mt-6 mb-8 md:my-[4.7rem] text-white md:pr-4 xl:ml-[20rem] 3xl:ml-[25rem]">
      <div className="flex flex-col gap-4 xl:flex-row xl:gap-6">
        <div className="bg-black w-full border border-[#919092] px-8 pt-2 pb-4 rounded-lg flex flex-col gap-2 text-sm xl:px-4 2xl:text-base">
          <p className="font-medium">PXA FDV</p>
          <p className="text-green font-bold">$4.7K</p>
        </div>

        <div className="bg-black w-full border border-[#919092] px-8 pt-2 pb-4 rounded-lg flex flex-col gap-2 text-sm xl:px-4 2xl:text-base">
          <p className="font-medium">PXA PRICE</p>
          <p className="text-green font-bold">$0.038915 / 0.062 XRP</p>
        </div>

        <div className="bg-black w-full border border-[#919092] px-8 pt-2 pb-4 rounded-lg flex flex-col gap-2 text-sm xl:px-4 2xl:text-base">
          <p className="font-medium">XRP PRICE</p>
          <p className="text-green font-bold">$0.6284</p>
        </div>
      </div>

      <div className="bg-black w-full border border-[#919092] px-4 pt-6 pb-10 rounded-lg flex flex-col gap-4 xs:px-8 md:gap-6 xl:px-12">
        <div className="flex justify-between gap-4 items-center">
          <h1 className="text-xs font-bold xs:text-lg xl:text-xl">
            PXA PORTAL
          </h1>

          <button className="w-fit flex items-center justify-center gap-2 text-xs font-[600] text-gold pt-1 pb-1.5 rounded-md xs:text-sm xl:text-base xl:pb-2">
            WHITEPAPER
            <FaChevronRight className="relative top-0.5" />
          </button>
        </div>

        <p className="text-xs font-thin leading-6 xs:text-sm xs:leading-7 xl:text-[15px]">
          PXA TOKEN IS AN ARBITRAGE TOKEN, TREAT IT LIKE DAY TRADING. IT IS NOT
          A LONG TERM HOLD ASSET LIKE PTC OR OTHER DEFLATIONARY TOKENS.
        </p>
      </div>

      <div className="bg-black w-full border border-[#919092] px-4 pt-6 pb-10 rounded-lg flex flex-col gap-4 xs:px-8 md:gap-6 xl:px-12">
        <h1 className="text-xs font-bold xs:text-lg xl:text-xl">
          XRP REFLECTIONS
        </h1>

        <div className="text-white flex flex-col gap-4 xl:flex-row xl:items-center xl:border xl:border-[#919092] xl:rounded-lg xl:px-3 xl:py-2">
          <label
            htmlFor="accumulatedAmount"
            className="text-xs min-w-fit xs:text-sm"
          >
            ACCUMULATED AMOUNT:
          </label>

          <input
            type="text"
            id="accumulatedAmount"
            placeholder="0"
            className="w-full text-sm xs:text-base bg-[transparent] border border-[#919092] outline-none rounded-md px-2 pt-0.5 pb-2 xl:p-0 xl:border-none"
          />

          <button className="w-full bg-gold text-sm font-[800] text-black px-2 pt-1 pb-2.5 rounded-md xl:max-w-[10rem] xl:text-base 3xl:text-lg">
            CLAIM
          </button>
        </div>
      </div>

      <div className="bg-black w-full border border-[#919092] px-4 pt-6 pb-10 rounded-lg flex flex-col gap-8 xs:px-8 md:gap-6 xl:px-12">
        <h1 className="text-xs font-bold xs:text-lg xl:text-xl">
          TRADE PXA/XRP
        </h1>

        <div className="flex flex-col gap-6">
          <div className="flex gap-4 xl:gap-8">
            <GoldBorderBtn btnLabel="BUY" />

            <GoldBorderBtn btnLabel="SELL" />
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex justify-between text-xs xs:text-sm md:text-base">
              <label htmlFor="XRP">XRP:</label>

              <p>BALANCE: 0 XRP</p>
            </div>

            <div className="bg-[#121015] flex items-center gap-2 border border-[#919092] rounded-md px-2 py-2">
              <input
                type="text"
                id="PXA"
                placeholder="0"
                className="w-full bg-[transparent] outline-none px-2 text-sm xs:text-base"
              />

              <button className="bg-[#121015] transition-colors duration-300 hover:bg-[#0e0e0f] min-w-[6rem] text-sm flex justify-center rounded-md border border-[#919092] pb-2 md:text-base md:max-w-[8rem]">
                MAX
              </button>
            </div>
          </div>

          <div className="flex flex-col gap-4">
            <div className="flex justify-between text-xs xs:text-sm md:text-base">
              <label htmlFor="PXA">PXA:</label>

              <p>BALANCE: 0 PXA</p>
            </div>

            <div
              type="text"
              className="w-full bg-[#121015] text-sm xs:text-base border border-[#919092] outline-none rounded-md px-4 pt-2 pb-3.5"
            >
              0
            </div>
          </div>

          <GoldBgBtn btnLabel="SWAP" />
        </div>
      </div>
    </div>
  );
};

export default PXAPortal;
