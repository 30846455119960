import React, { useEffect, useRef, useState } from "react";
import { AiFillDollarCircle } from "react-icons/ai";
import { FaBurn, FaDiscord, FaTwitter } from "react-icons/fa";
import { GiWhiteBook } from "react-icons/gi";
import { HiMenu } from "react-icons/hi";
import { RiDashboardFill, RiExchangeFill, RiNftFill } from "react-icons/ri";
import BrandLogo from "../assets/images/Main-Logo-Mockup-3D.png";
import GoldBgBtn from "./buttons/GoldBgBtn";
import SidebarNavBtn from "./buttons/SidebarNavBtn";
import SocialBtn from "./buttons/SocialBtn";

const Sidebar = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  const sidebarRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleButtonClick = () => {
    setIsSidebarVisible((prev) => !prev);
  };

  return (
    <div ref={sidebarRef} className="text-white mt-4">
      <button className="text-white md:hidden" onClick={handleButtonClick}>
        <HiMenu className="w-8 h-8" />
      </button>

      <div
        className={`${
          isSidebarVisible ? "translate-x-0" : "-translate-x-full"
        } fixed top-0 left-0 h-full w-[17rem] bg-black p-6 transition-transform ease-in-out duration-300 z-10 md:bg-[transparent] md:translate-x-0 xl:w-[20rem] 3xl:w-[25rem]`}
      >
        <button
          className="absolute z-30 top-4 right-4 text-white focus:outline-none md:hidden"
          onClick={handleButtonClick}
        >
          <HiMenu className="w-8 h-8" />
        </button>

        <div className="relative mt-2 2xl:mt-4">
          <div className="flex justify-center">
            <img src={BrandLogo} alt="" className="w-40 2xl:w-44 3xl:w-52" />
          </div>

          <GoldBgBtn btnLabel="Connect Wallet" />

          <ul className="mt-6 flex flex-col gap-3 2xl:gap-6 2xl:mt-10 3xl:gap-8 3xl:mt-12">
            <li>
              <SidebarNavBtn icon={RiDashboardFill} label="Dappboard" />
            </li>
            <li>
              <SidebarNavBtn icon={RiExchangeFill} label="Trade Portal" />
            </li>
            <li>
              <SidebarNavBtn icon={AiFillDollarCircle} label="Stake Portal" />
            </li>
            <li>
              <SidebarNavBtn icon={FaBurn} label="Burn Portal" />
            </li>
            <li>
              <SidebarNavBtn icon={RiNftFill} label="NFT Portal" />
            </li>
            <li>
              <SidebarNavBtn icon={RiNftFill} label="PXA Portal" />
            </li>
          </ul>

          <ul className="mt-4 flex flex-col gap-4 2xl:mt-8 2xl:gap-5 3xl:gap-6 3xl:mt-12">
            <li>
              <SocialBtn icon={FaDiscord} label="Discord" />
            </li>
            <li>
              <SocialBtn icon={FaTwitter} label="Twitter" />
            </li>
            <li>
              <SocialBtn icon={GiWhiteBook} label="Whitepaper" />
            </li>
          </ul>

          <div className="hidden md:block absolute -top-8 -right-6 h-screen w-[2px] bg-[#919092]" />
        </div>
      </div>

      {isSidebarVisible && (
        <div
          className="fixed top-0 left-0 h-full w-full bg-black opacity-60 transition-colors z-9 md:hidden"
          onClick={handleButtonClick}
        />
      )}
    </div>
  );
};

export default Sidebar;
