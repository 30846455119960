import React from "react";

const GoldBgBtn = (props) => {
  return (
    <button className="w-full bg-gold text-sm font-[800] text-black px-2 pt-1.5 pb-3 rounded-md xl:text-base 3xl:text-lg 3xl:pt-2.5 3xl:pb-4">
      {props.btnLabel}
    </button>
  );
};

export default GoldBgBtn;
