import React, { useState } from "react";

const SidebarNavBtn = ({ icon: Icon, label }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button
      className={`w-full bg-[#00000079] pt-1 pb-2.5 border rounded-lg flex items-center gap-2 px-2 transition-colors duration-300  2xl:text-[15px] 2xl:gap-3 2xl:px-4 2xl:pt-2.5 2xl:pb-4 3xl:text-lg 3xl:gap-4 ${
        isHovered ? "border-gold text-gold" : "border-[#919092] text-white"
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {Icon && (
        <Icon
          className={`w-6 h-6 2xl:w-7 2xl:h-7 3xl:w-8 3xl:h-8 relative top-0.5 ${
            isHovered ? "text-gold" : "text-white"
          } transition-colors duration-300`}
        />
      )}
      {label}
    </button>
  );
};

export default SidebarNavBtn;
