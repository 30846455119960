import React, { useState } from "react";

const GoldBorderBtn = (props) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <button
      className={`w-full text-center text-xs xs:text-sm bg-[#00000079] pt-1 pb-2.5 border rounded-lg flex justify-center px-2 transition-colors duration-300 md:text-base 2xl:px-4 2xl:pt-2.5 2xl:pb-4 3xl:text-lg ${
        isHovered ? "border-gold text-gold" : "border-[#919092] text-white"
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {props.btnLabel}
    </button>
  );
};

export default GoldBorderBtn;
