import React, { useState } from "react";

const SocialBtn = ({ icon: Icon, label }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <button
      className={`flex items-center gap-2 transition-colors duration-300 px-2 2xl:text-[15px] 2xl:gap-3 3xl:text-lg 3xl:gap-4 ${
        isHovered ? `text-gold` : `text-white`
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {Icon && (
        <Icon
          className={`w-6 h-6 2xl:w-7 2xl:h-7 3xl:w-8 3xl:h-8 relative top-0.5 ${
            isHovered ? `text-gold` : "text-white"
          } transition-colors duration-300`}
        />
      )}
      {label}
    </button>
  );
};

export default SocialBtn;
