import PXAPortal from "./components/PXAPortal";
import Sidebar from "./components/Sidebar";

function App() {
  return (
    <div className="min-h-screen bg-[url('./assets/images/Landing_area_BG_mobile_version.png')] sm:bg-[url('./assets/images/Landing_area_BG.png')] bg-contain bg-no-repeat bg-black font-fmbolyarsanspro w-full flex justify-center px-4">
      <div className="relative w-full flex flex-col md:flex-row md:gap-6 xl:gap-16">
        <Sidebar />

        <PXAPortal />
      </div>
    </div>
  );
}

export default App;
